<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M39 19C39.0014 16.2739 38.2599 13.599 36.8551 11.2627C35.4504 8.92638 33.4355 7.01708 31.027 5.73999C28.6186 4.46289 25.9076 3.8663 23.1855 4.01432C20.4635 4.16234 17.8332 5.04939 15.5774 6.58011C13.3216 8.11084 11.5256 10.2274 10.3825 12.7022C9.23937 15.1771 8.79228 17.9167 9.08931 20.6265C9.38633 23.3364 10.4162 25.9141 12.0683 28.0826C13.7204 30.251 15.9322 31.9283 18.466 32.934L24 44L29.534 32.934C32.3254 31.826 34.72 29.9056 36.4077 27.4213C38.0954 24.937 38.9984 22.0033 39 19Z"
		fill="currentColor"
	/>
	<path
		d="M24 5C26.7689 5 29.4756 5.8211 31.7779 7.35946C34.0802 8.89782 35.8747 11.0844 36.9343 13.6424C37.9939 16.2007 38.2711 19.0156 37.7309 21.7314C37.1907 24.4471 35.8575 26.9417 33.8993 28.8996C31.9414 30.8575 29.4469 32.1907 26.7311 32.7309C24.0156 33.2713 21.2007 32.9939 18.6424 31.9343C16.0841 30.8747 13.8978 29.0802 12.3595 26.7781C10.8211 24.4758 10 21.7689 10 19C10 15.2869 11.4749 11.726 14.1006 9.10059C16.726 6.47513 20.2869 5 24 5Z"
		fill="currentColor"
	/>
	<path
		d="M19.831 17.7637V17.7637C20.1265 18.1938 20.5843 18.473 21.0901 18.5315C21.7263 18.4646 22.3095 18.1335 22.7071 17.6135C23.1122 17.0668 23.7351 16.7415 24.3994 16.7297C25.2161 16.7297 25.9602 17.2166 26.3125 17.9816C26.5001 18.3095 26.8348 18.5165 27.2019 18.5315C27.7087 18.507 28.1732 18.2307 28.4499 17.7892C28.5191 17.6653 28.6005 17.5493 28.6928 17.4431C28.7593 17.3668 28.7657 17.2527 28.708 17.169L25.0822 11.8981C24.9804 11.7507 24.9093 11.5828 24.8732 11.4053C24.797 10.9286 24.4878 10.5271 24.0567 10.345C23.6249 10.1801 23.1411 10.2694 22.7906 10.5787C22.44 10.8881 22.2772 11.3694 22.3644 11.8384C22.4661 12.4135 22.6898 12.9578 23.0193 13.432L23.2689 13.7956L19.1659 16.7236C19.1068 16.7652 19.072 16.8352 19.0735 16.9093C19.0751 16.9835 19.1127 17.0518 19.1735 17.0908C19.4392 17.2602 19.6641 17.4903 19.831 17.7637V17.7637Z"
		fill="white"
	/>
	<path
		d="M29.5 14.8964C30.6099 14.8964 31.5096 13.9623 31.5096 12.81C31.5096 11.6577 30.6099 10.7236 29.5 10.7236C28.3901 10.7236 27.4904 11.6577 27.4904 12.81C27.4904 13.9623 28.3901 14.8964 29.5 14.8964Z"
		fill="white"
	/>
	<path
		d="M14.8353 21.6378C16.0166 21.5748 17.1369 21.0735 17.9923 20.2252C18.8092 21.0908 19.922 21.587 21.0892 21.6061C22.2817 21.5835 23.4264 21.1157 24.3139 20.2884C25.059 21.1258 26.1063 21.6032 27.2043 21.6061C28.3531 21.6037 29.4544 21.1301 30.2683 20.2884C31.0706 21.1677 32.2005 21.6429 33.366 21.5912C33.59 21.5756 33.7989 21.4683 33.9466 21.2929C34.0944 21.1174 34.1689 20.8882 34.1538 20.6556C34.1096 20.1774 33.7106 19.82 33.2484 19.8448C31.9233 19.9713 30.9909 18.4779 30.985 18.4656C30.8156 18.2225 30.5428 18.0803 30.2539 18.0843C29.965 18.0883 29.696 18.2381 29.533 18.4858C29.0233 19.3145 28.151 19.8268 27.2043 19.8536C26.3496 19.8329 25.5719 19.3357 25.1693 18.5526C25.0277 18.2888 24.77 18.1143 24.4804 18.0861C24.1908 18.0578 23.9064 18.1794 23.7199 18.4111C23.0795 19.2567 22.123 19.7795 21.0892 19.8492C20.1438 19.7937 19.2786 19.2795 18.7538 18.4612C18.599 18.23 18.3502 18.0857 18.0794 18.0703C17.8086 18.0548 17.546 18.1699 17.3678 18.3821C17.356 18.398 16.151 19.9906 14.7363 19.8755C14.5107 19.8584 14.2881 19.9376 14.1201 20.0948C13.9521 20.252 13.8531 20.4736 13.8461 20.7083C13.8385 20.8726 13.8825 21.6378 14.8353 21.6378Z"
		fill="white"
	/>
	<path
		d="M33.2484 23.9394C31.9073 24.0588 30.9917 22.5725 30.985 22.5602C30.8162 22.3141 30.5415 22.1701 30.2506 22.1754C29.9597 22.1807 29.6899 22.3345 29.5296 22.5865C29.0207 23.4136 28.1493 23.9241 27.2043 23.949C26.3509 23.9266 25.5751 23.4292 25.1736 22.6471C25.0314 22.3835 24.7734 22.2093 24.4836 22.1812C24.1937 22.1531 23.9092 22.2748 23.7224 22.5066C23.0825 23.3547 22.1247 23.8793 21.0892 23.949C20.1436 23.8933 19.2784 23.3788 18.7538 22.5602C18.5988 22.3292 18.3501 22.1852 18.0794 22.1698C17.8087 22.1543 17.5462 22.2692 17.3678 22.4811C17.3492 22.5057 16.0901 24.0825 14.7363 23.9745C14.5107 23.9579 14.2883 24.0374 14.1203 24.1947C13.9524 24.352 13.8534 24.5735 13.8461 24.8082C13.8377 24.9724 13.8817 25.7376 14.8344 25.7376C16.0159 25.6741 17.1363 25.1729 17.9923 24.325C18.8092 25.1906 19.922 25.6868 21.0892 25.706C22.2818 25.6837 23.4266 25.2159 24.3139 24.3883C25.0533 25.2338 26.1043 25.713 27.2043 25.706C28.3533 25.7033 29.4547 25.2297 30.2691 24.3883C31.073 25.2646 32.2013 25.7392 33.366 25.691C33.6683 25.6692 33.9365 25.4816 34.0694 25.1989C34.2024 24.9162 34.18 24.5813 34.0106 24.3204C33.8413 24.0596 33.5507 23.9123 33.2484 23.9341V23.9394Z"
		fill="white"
	/>
</svg>
