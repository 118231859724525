<script lang="ts">
	import blockState from '$src/lib/stores/block-state'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		title: string
		description?: string | null
		size?: 'small' | 'large'
		iconUrl?: string
		bgColor?: string
		imageUrl?: string | null
		blockId?: string | null
		isPublic?: boolean
		children?: import('svelte').Snippet
	}

	let {
		title,
		description = '',
		size = 'large',
		iconUrl = '/visuals/icons/fork-knife.svg',
		bgColor = 'bg-red-200',
		imageUrl = null,
		blockId = null,
		isPublic = false,
		children,
	}: Props = $props()

	function handleSetHoverState() {
		if (isPublic) return
		if (blockId != null) blockState.setHoveringPlaceId(blockId)
	}

	function handleUnsetHoverState() {
		if (isPublic) return
		if (blockId != null) blockState.cleartHoveringPlaceId()
	}
</script>

{#if size == 'large'}
	<div class="w-full flex items-center justify-between my-1 overflow-x-hidden">
		<button
			onclick={stopPropagation(null)}
			onmouseenter={handleSetHoverState}
			onmouseleave={handleUnsetHoverState}
			disabled={!blockId}
			class="flex items-center text-brand-gray-5 truncate mr-4 group-hover:text-black dark:text-white group py-1 rounded-lg cursor-pointer touch-manipulation"
		>
			{#if imageUrl != null}
				<span class="relative inline-block flex-shrink-0 pb-1">
					<img class="h-8 w-8 rounded-md object-cover" src={imageUrl} alt={title} />
					<img
						class={`absolute bottom-1 right-0 block translate-x-1/2 translate-y-1/2 transform rounded-full p-[2px] border-[3px] border-brand-gray-5 h-[20px] w-[20px] ${bgColor}`}
						src={iconUrl}
						alt={title}
					/>
				</span>
			{:else}
				<div class={`flex flex-shrink-0 items-center justify-center h-8 w-8 rounded-md ${bgColor}`}>
					<img class="h-5 w-5" src={iconUrl} alt={title} />
				</div>
			{/if}
			<div class="pl-3 text-start w-full">
				<div
					class={`text-xs font-medium dark:text-brand-gray-2 text-black truncate w-full pr-10 dark:group-hover:text-white transition-all ${
						isPublic ? 'blur-sm' : 'blur-0'
					}`}
				>
					{title}
				</div>
				<p
					class={`text-xs dark:text-brand-gray-3 text-brand-gray-5 truncate w-full pr-10 dark:group-hover:text-brand-gray-2 transition-all ${
						isPublic ? 'blur-sm' : 'blur-0'
					}`}
				>
					{description ?? 'No description available'}
				</p>
			</div>
		</button>
		{@render children?.()}
	</div>
{:else}
	<button
		onclick={stopPropagation(null)}
		class="flex group items-center space-x-2 touch-manipulation"
	>
		{@render children?.()}
		<div
			class="text-[10px] font-medium text-brand-gray-5 group-hover:text-black dark:group-hover:text-white dark:text-brand-gray-3 transition-colors"
		>
			{title}
		</div>
	</button>
{/if}
