<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M39 19C39.0014 16.2739 38.2599 13.599 36.8551 11.2627C35.4504 8.92638 33.4355 7.01708 31.027 5.73999C28.6186 4.46289 25.9076 3.8663 23.1855 4.01432C20.4635 4.16234 17.8332 5.04939 15.5774 6.58011C13.3216 8.11084 11.5256 10.2274 10.3825 12.7022C9.23937 15.1771 8.79228 17.9167 9.08931 20.6265C9.38633 23.3364 10.4162 25.9141 12.0683 28.0826C13.7204 30.251 15.9322 31.9283 18.466 32.934L24 44L29.534 32.934C32.3254 31.826 34.72 29.9056 36.4077 27.4213C38.0954 24.937 38.9984 22.0033 39 19Z"
		fill="currentColor"
	/>
	<path
		d="M24 5C26.7689 5 29.4756 5.8211 31.7779 7.35946C34.0802 8.89782 35.8747 11.0844 36.9343 13.6424C37.9939 16.2007 38.2711 19.0156 37.7309 21.7314C37.1907 24.4471 35.8575 26.9417 33.8993 28.8996C31.9414 30.8575 29.4469 32.1907 26.7311 32.7309C24.0156 33.2713 21.2007 32.9939 18.6424 31.9343C16.0841 30.8747 13.8978 29.0802 12.3595 26.7781C10.8211 24.4758 10 21.7689 10 19C10 15.2869 11.4749 11.726 14.1006 9.10059C16.726 6.47513 20.2869 5 24 5Z"
		fill="currentColor"
	/>
	<path
		d="M24 20.5C15.2809 20.5 13.995 24.2792 14.6434 26.5283C14.8891 27.3835 15.6703 27.9734 16.56 27.9758L31.4242 28C32.3054 28.0016 33.0838 27.4262 33.3409 26.5833C34.0259 24.3417 32.79 20.5 24 20.5Z"
		fill="white"
	/>
	<path
		d="M24.625 19.25C30.2358 19.25 31.7316 18.6733 31.7316 17.0625C31.7316 15.4517 30.2358 13.4167 24.625 13.4167C19.0141 13.4167 17.5183 15.4517 17.5183 17.0625C17.5183 18.6733 19.0141 19.25 24.625 19.25Z"
		fill="white"
	/>
	<path
		d="M23.6042 12.1667C27.5101 12.1667 28.5517 11.755 28.5517 10.6042C28.5517 9.45333 27.5101 8 23.6042 8C19.6984 8 18.6559 9.45333 18.6559 10.6042C18.6559 11.755 19.6976 12.1667 23.6042 12.1667Z"
		fill="white"
	/>
</svg>
