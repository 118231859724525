<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M39 19C39.0014 16.2739 38.2599 13.599 36.8551 11.2627C35.4504 8.92638 33.4355 7.01708 31.027 5.73999C28.6186 4.46289 25.9076 3.8663 23.1855 4.01432C20.4635 4.16234 17.8332 5.04939 15.5774 6.58011C13.3216 8.11084 11.5256 10.2274 10.3825 12.7022C9.23937 15.1771 8.79228 17.9167 9.08931 20.6265C9.38633 23.3364 10.4162 25.9141 12.0683 28.0826C13.7204 30.251 15.9322 31.9283 18.466 32.934L24 44L29.534 32.934C32.3254 31.826 34.72 29.9056 36.4077 27.4213C38.0954 24.937 38.9984 22.0033 39 19Z"
		fill="currentColor"
	/>
	<path
		d="M24 5C26.7689 5 29.4756 5.8211 31.7779 7.35946C34.0802 8.89782 35.8747 11.0844 36.9343 13.6424C37.9939 16.2007 38.2711 19.0156 37.7309 21.7314C37.1907 24.4471 35.8575 26.9417 33.8993 28.8996C31.9414 30.8575 29.4469 32.1907 26.7311 32.7309C24.0156 33.2713 21.2007 32.9939 18.6424 31.9343C16.0841 30.8747 13.8978 29.0802 12.3595 26.7781C10.8211 24.4758 10 21.7689 10 19C10 15.2869 11.4749 11.726 14.1006 9.10059C16.726 6.47513 20.2869 5 24 5Z"
		fill="currentColor"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M20.3616 18.7917C19.5008 18.8078 18.6813 18.4229 18.1441 17.75C18.0716 17.6525 17.8375 17.3433 17.6666 17.125C17.5646 16.9976 17.5464 16.8224 17.62 16.6767C18.3203 15.2966 18.686 13.771 18.6875 12.2233V10.25C18.6875 9.55965 19.2471 9.00001 19.9375 9.00001H20.7708C21.103 8.9989 21.4221 9.13011 21.6574 9.36465C21.8927 9.59919 22.025 9.91776 22.025 10.25V12.2233C22.0238 13.7846 22.3952 15.3236 23.1083 16.7125C23.1813 16.8581 23.1627 17.033 23.0608 17.16C22.8941 17.3675 22.6733 17.6517 22.59 17.7608C22.0505 18.4291 21.2319 18.8098 20.3733 18.7917H20.3616ZM21.1875 16.0833C21.1875 15.6231 20.8144 15.25 20.3541 15.25C19.8939 15.25 19.5208 15.6231 19.5208 16.0833C19.5208 16.5436 19.8939 16.9167 20.3541 16.9167C20.8144 16.9167 21.1875 16.5436 21.1875 16.0833Z"
		fill="white"
	/>
	<path
		d="M23.7858 18.4483C23.7056 18.4352 23.6246 18.4672 23.575 18.5317C22.799 19.5034 21.6167 20.061 20.3733 20.0417H20.3567C19.1087 20.0565 17.9249 19.4901 17.1533 18.5092C17.102 18.4423 17.0182 18.4089 16.9349 18.4222C16.8517 18.4354 16.7823 18.4931 16.7542 18.5725C16.386 19.5796 16.1943 20.6427 16.1875 21.715V26.9167C16.1875 27.4692 16.407 27.9991 16.7977 28.3898C17.1884 28.7805 17.7183 29 18.2708 29H22.4375C23.5881 29 24.5208 28.0673 24.5208 26.9167V21.715C24.5134 20.65 24.3234 19.5942 23.9592 18.5933C23.9325 18.5168 23.8659 18.461 23.7858 18.4483Z"
		fill="white"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M29.7292 27.75H30.7708H30.775C31.1202 27.75 31.4 28.0298 31.4 28.375C31.4 28.7202 31.1202 29 30.775 29H26.6083C26.2631 29 25.9833 28.7202 25.9833 28.375C25.9833 28.0298 26.2631 27.75 26.6083 27.75H27.65C27.8801 27.75 28.0667 27.5635 28.0667 27.3334V25.9267C28.0667 25.7437 27.9474 25.5821 27.7725 25.5283C26.4624 25.1235 25.5683 23.9129 25.5667 22.5417C25.5667 21.7592 26.2442 16.92 26.3567 16.1433C26.4254 15.6297 26.8651 15.2471 27.3833 15.25H30C30.5223 15.2512 30.9619 15.6415 31.025 16.16C31.1375 16.935 31.8125 21.7617 31.8125 22.5417C31.8108 23.9129 30.9168 25.1235 29.6067 25.5283C29.4318 25.5821 29.3124 25.7437 29.3125 25.9267V27.3334C29.3125 27.5635 29.499 27.75 29.7292 27.75ZM27.9258 16.5C27.7183 16.5 27.5424 16.6528 27.5133 16.8583L27.4911 17.016L27.4911 17.0161L27.4911 17.0161C27.4458 17.3376 27.3932 17.7111 27.3333 18.1083C27.3171 18.2282 27.3537 18.3491 27.4337 18.4398C27.5137 18.5305 27.6291 18.5819 27.75 18.5808H29.6183C29.7392 18.5819 29.8546 18.5305 29.9346 18.4398C30.0146 18.3491 30.0512 18.2282 30.035 18.1083L30.0104 17.9293L30.0103 17.929L30.0102 17.9278C29.9556 17.5304 29.9051 17.1635 29.8617 16.8583C29.8326 16.6528 29.6567 16.5 29.4492 16.5H27.9258ZM28.0625 23.5833C27.7173 23.5833 27.4375 23.3035 27.4375 22.9583C27.4375 22.6132 27.7173 22.3333 28.0625 22.3333C28.4077 22.3333 28.6875 22.6132 28.6875 22.9583C28.6877 23.1242 28.6219 23.2833 28.5047 23.4005C28.3874 23.5178 28.2283 23.5836 28.0625 23.5833ZM29.3125 19.8333C28.9673 19.8333 28.6875 20.1132 28.6875 20.4583C28.6875 20.8035 28.9673 21.0833 29.3125 21.0833C29.6577 21.0833 29.9375 20.8035 29.9375 20.4583C29.9375 20.1132 29.6577 19.8333 29.3125 19.8333Z"
		fill="white"
	/>
</svg>
