<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M39 19C39.0014 16.2739 38.2599 13.599 36.8551 11.2627C35.4504 8.92638 33.4355 7.01708 31.027 5.73999C28.6186 4.46289 25.9076 3.8663 23.1855 4.01432C20.4635 4.16234 17.8332 5.04939 15.5774 6.58011C13.3216 8.11084 11.5256 10.2274 10.3825 12.7022C9.23937 15.1771 8.79228 17.9167 9.08931 20.6265C9.38633 23.3364 10.4162 25.9141 12.0683 28.0826C13.7204 30.251 15.9322 31.9283 18.466 32.934L24 44L29.534 32.934C32.3254 31.826 34.72 29.9056 36.4077 27.4213C38.0954 24.937 38.9984 22.0033 39 19Z"
		fill="currentColor"
	/>
	<path
		d="M24 5C26.7689 5 29.4756 5.8211 31.7779 7.35946C34.0802 8.89782 35.8747 11.0844 36.9343 13.6424C37.9939 16.2007 38.2711 19.0156 37.7309 21.7314C37.1907 24.4471 35.8575 26.9417 33.8993 28.8996C31.9414 30.8575 29.4469 32.1907 26.7311 32.7309C24.0156 33.2713 21.2007 32.9939 18.6424 31.9343C16.0841 30.8747 13.8978 29.0802 12.3595 26.7781C10.8211 24.4758 10 21.7689 10 19C10 15.2869 11.4749 11.726 14.1006 9.10059C16.726 6.47513 20.2869 5 24 5Z"
		fill="currentColor"
	/>
	<path
		d="M31.8166 18.4352C31.6266 18.4087 31.4401 18.3621 31.2608 18.296C31.16 18.2584 31.0625 18.216 30.9675 18.1704C30.8498 18.1136 30.711 18.1136 30.5933 18.1704C30.4983 18.216 30.4008 18.2576 30.3016 18.2952C29.4495 18.6125 28.4924 18.5327 27.71 18.0792C27.6319 18.0336 27.5577 17.9819 27.4883 17.9248C27.4741 17.9136 27.4616 17.9008 27.4491 17.8888C27.3817 17.8188 27.2903 17.7743 27.1916 17.7632C26.3559 17.6801 25.5687 17.3464 24.9416 16.8096C24.8659 16.7411 24.7774 16.6868 24.6808 16.6496C24.4874 16.5768 24.2725 16.5768 24.0791 16.6496C23.9827 16.6871 23.8943 16.7413 23.8183 16.8096C22.6679 17.7769 21.0594 18.0646 19.6233 17.56C19.5057 17.5188 19.4137 17.429 19.3727 17.3154C19.3317 17.2019 19.346 17.0767 19.4116 16.9744L20.2983 15.5904C20.3742 15.472 20.5086 15.4 20.6533 15.4H26.1841C26.2922 15.3998 26.3896 15.3375 26.4316 15.242C26.4735 15.1464 26.4519 15.036 26.3766 14.9616C26.3071 14.8942 26.2412 14.8234 26.1791 14.7496C26.0015 14.5386 25.8589 14.3025 25.7566 14.0496C25.6928 13.8985 25.5398 13.7998 25.37 13.8H23.1091C22.9503 13.8001 22.8052 13.7136 22.735 13.5768L21.4075 10.9904C21.0608 10.4187 20.4836 10.0097 19.8124 9.86006C19.1411 9.71043 18.4353 9.83343 17.8616 10.2L15.5833 11.66C15.2004 11.9052 15.097 12.402 15.3525 12.7696C15.6079 13.1372 16.1254 13.2364 16.5083 12.9912L18.7858 11.5328C18.9771 11.4105 19.2126 11.3695 19.4365 11.4194C19.6604 11.4694 19.8528 11.606 19.9683 11.7968L20.8483 13.5112C20.8803 13.5732 20.8767 13.6467 20.8387 13.7054C20.8007 13.7642 20.7337 13.8 20.6616 13.8H20.4208C19.8395 13.8017 19.3002 14.0911 18.9941 14.5656C17.3333 17.164 16.0458 18.752 16.0458 21.8C16.0458 25.9296 19.1616 28.2128 22.0775 28.972C22.1487 28.9904 22.2221 28.9999 22.2958 29H26.4625C26.5362 28.9999 26.6096 28.9904 26.6808 28.972C29.5975 28.2128 32.7125 25.932 32.7125 21.8C32.7246 20.7386 32.5316 19.6843 32.1433 18.6904C32.0905 18.5549 31.9656 18.4573 31.8166 18.4352Z"
		fill="white"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M34.7324 12.5328C34.8759 13.0392 34.7585 13.5809 34.4166 13.9904C34.2153 14.2324 33.9594 14.4274 33.6683 14.5608C33.6159 14.5841 33.576 14.6272 33.5581 14.6799C33.5403 14.7325 33.5461 14.79 33.5741 14.8384C33.7276 15.1065 33.8167 15.4042 33.8349 15.7096C33.8635 16.212 33.6481 16.6989 33.2516 17.028C32.7941 17.3145 32.2215 17.374 31.7108 17.188C31.4138 17.0771 31.1464 16.9038 30.9283 16.6808C30.889 16.6399 30.8337 16.6167 30.7758 16.6167C30.7178 16.6167 30.6626 16.6399 30.6233 16.6808C30.4054 16.9038 30.1383 17.0771 29.8416 17.188C29.3526 17.3688 28.8041 17.3231 28.3549 17.064C27.9273 16.7353 27.6907 16.2291 27.7191 15.704C27.7365 15.3984 27.8257 15.1006 27.9799 14.8328C28.008 14.7844 28.0138 14.7269 27.9959 14.6743C27.978 14.6216 27.9381 14.5785 27.8858 14.5552C27.5971 14.4241 27.3426 14.2328 27.1408 13.9952C26.8113 13.6044 26.6883 13.0895 26.8074 12.6C26.9992 12.1079 27.4249 11.7337 27.9524 11.5936C28.2603 11.5145 28.5829 11.5036 28.8958 11.5616C28.9529 11.573 29.0123 11.561 29.0598 11.5285C29.1072 11.4959 29.1384 11.4459 29.1458 11.3904C29.1855 11.0868 29.2957 10.7956 29.4683 10.5384C29.7521 10.1161 30.2234 9.84354 30.7449 9.79999C31.2912 9.82525 31.7918 10.1001 32.0916 10.5392C32.2636 10.7968 32.3733 11.0883 32.4124 11.392C32.4196 11.4476 32.4507 11.4978 32.4982 11.5304C32.5458 11.5629 32.6053 11.5748 32.6624 11.5632C32.9754 11.5056 33.2978 11.5165 33.6058 11.5952C34.1122 11.7236 34.5281 12.0698 34.7324 12.5328ZM29.5299 13.8C29.5299 14.4627 30.0896 15 30.7799 15C31.1115 15 31.4294 14.8736 31.6638 14.6485C31.8982 14.4235 32.0299 14.1182 32.0299 13.8C32.0299 13.1372 31.4703 12.6 30.7799 12.6C30.0896 12.6 29.5299 13.1372 29.5299 13.8Z"
		fill="white"
	/>
</svg>
