<script lang="ts">
	import AppScreen from './AppScreen.svelte'
	import StickyHeader from '../display/StickyHeader.svelte'
	import { Motion } from 'svelte-motion'
	import PanelHeader from '$src/lib/components/itineraries/PanelHeader.svelte'
	import Organizer from '$lib/components/itineraries/Organizer.svelte'
	import ImmutableTitle from '$src/lib/components/itineraries/ImmutableTitle.svelte'
	import PanelBody from '$src/lib/components/itineraries/PanelBody.svelte'
	import SleekButton from '$src/lib/components/buttons/SleekButton.svelte'
	import BlockTemplateStatic from './BlockTemplateStatic.svelte'
	import PlacePinIcon from '../icons/PlacePinIcon.svelte'
	import CustomPinIcon from '../icons/CustomPinIcon.svelte'
	import CustomRegionIcon from '../icons/CustomRegionIcon.svelte'
	interface Props {
		[key: string]: any
	}

	let { ...props }: Props = $props()

	let computedSrc = '/visuals/stock/creator.webp'
</script>

<AppScreen>
	<Motion {...props}>
		{#snippet children({ motion })}
			<div
				class="absolute bottom-0 w-full left-0 right-0 bg-brand-gray-5 h-2/3 rounded-t-xl z-20 overflow-y-hidden"
				use:motion
			>
				<PanelHeader
					class="rounded-t-xl bg-brand-gray-6 dark:bg-brand-gray-6 dark:lg:bg-brand-gray-6"
				>
					<div class="flex flex-col w-full">
						<div class="flex items-center justify-between w-full">
							<Organizer
								isStatic={true}
								imageUrl={computedSrc}
								name={'Sarah Smith'}
								isPublic={false}
								size="xs"
							/>

							<div class="flex items-center flex-shrink-0 gap-x-2">
								<SleekButton onclick={null}>Menu</SleekButton>
							</div>
						</div>

						<ImmutableTitle title={'Tulum for Solo Females'} class="mt-4 lg:mt-7 pb-[6px]" />
					</div>
				</PanelHeader>
				<PanelBody>
					<div class="px-4 mt-2 w-full">
						<p class="text-xs text-brand-gray-4 mt-3">Add a Highlight</p>
						<div
							class="mt-2 w-full group rounded-md border-2 border-dashed cursor-pointer border-brand-gray-4 text-center dark:hover:border-brand-gray-2 transition-all hover:border-black focus:outline-none focus:ring-0"
						>
							<span
								class="mx-auto p-2 block text-xs text-brand-gray-4 dark:group-hover:text-brand-gray-2 transition-all"
								>Upload an Image</span
							>
						</div>
					</div>

					<ul class="px-4 flex flex-col space-y-6">
						<li class="w-full">
							<StickyHeader class="top-24 lg:top-32 w-full" title="Tulum Town" />
							<div class="flex flex-col mt-2">
								<BlockTemplateStatic
									title={'Temple of the Wind'}
									description={'Spa, yoga, and meditation'}
									blockId={null}
									isPublic={false}
									size="large"
									iconUrl={'/visuals/icons/custom/white/spa-stone-1.svg'}
									bgColor={'bg-sky-600'}
								/>
								<BlockTemplateStatic
									title={'Nomad House on the Beach'}
									description={'Great for sunset coconuts and beach vibes'}
									blockId={null}
									isPublic={false}
									size="large"
									iconUrl={'/visuals/icons/custom/white/champagne-bottle-glass.svg'}
									bgColor={'bg-green-600'}
								/>
								<BlockTemplateStatic
									title={'La Velenta'}
									description={'Best area to stay'}
									blockId={null}
									isPublic={false}
									size="large"
									iconUrl={'/visuals/icons/select-all.svg'}
									bgColor={'bg-orange-600'}
								/>
							</div>

							<p class="text-xs text-brand-gray-4 mt-2">Add a block</p>
							<div class="flex flex-row flex-shrink-0 space-x-4 mt-2">
								<BlockTemplateStatic title="Place" size="small">
									<PlacePinIcon class="h-[14px] w-[14px] m-auto text-brand-gray-4/70" />
								</BlockTemplateStatic>
								<BlockTemplateStatic title="Pin" size="small">
									<CustomPinIcon class="h-[14px] w-[14px] m-auto text-brand-gray-4/70" />
								</BlockTemplateStatic>
								<BlockTemplateStatic title="Region" size="small">
									<CustomRegionIcon class="h-[14px] w-[14px] m-auto text-brand-gray-4/70" />
								</BlockTemplateStatic>
							</div>
						</li>
					</ul>
				</PanelBody>
			</div>
		{/snippet}
	</Motion>
</AppScreen>
