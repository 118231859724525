<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
	><path
		d="M39 19C39.0014 16.2739 38.2599 13.599 36.8551 11.2627C35.4504 8.92638 33.4355 7.01708 31.027 5.73999C28.6186 4.46289 25.9076 3.8663 23.1855 4.01432C20.4635 4.16234 17.8332 5.04939 15.5774 6.58011C13.3216 8.11084 11.5256 10.2274 10.3825 12.7022C9.23937 15.1771 8.79228 17.9167 9.08931 20.6265C9.38633 23.3364 10.4162 25.9141 12.0683 28.0826C13.7204 30.251 15.9322 31.9283 18.466 32.934L24 44L29.534 32.934C32.3254 31.826 34.72 29.9056 36.4077 27.4213C38.0954 24.937 38.9984 22.0033 39 19Z"
		fill="currentColor"
	/>
	<path
		d="M24 5C26.7689 5 29.4756 5.8211 31.7779 7.35946C34.0802 8.89782 35.8747 11.0844 36.9343 13.6424C37.9939 16.2007 38.2711 19.0156 37.7309 21.7314C37.1907 24.4471 35.8575 26.9417 33.8993 28.8996C31.9414 30.8575 29.4469 32.1907 26.7311 32.7309C24.0156 33.2713 21.2007 32.9939 18.6424 31.9343C16.0841 30.8747 13.8978 29.0802 12.3595 26.7781C10.8211 24.4758 10 21.7689 10 19C10 15.2869 11.4749 11.726 14.1006 9.10059C16.726 6.47513 20.2869 5 24 5Z"
		fill="currentColor"
	/>
	<path
		d="M33.7275 16.7632L25.1234 9.2216C24.4885 8.6573 23.5082 8.6573 22.8734 9.2216L14.2725 16.7632C13.9439 17.0634 13.9258 17.5609 14.2319 17.8824C14.538 18.2039 15.0557 18.2313 15.3967 17.944L23.7192 10.6488C23.8783 10.5095 24.1218 10.5095 24.2809 10.6488L32.6034 17.944C32.9444 18.2313 33.4621 18.2039 33.7682 17.8824C34.0743 17.5609 34.0562 17.0634 33.7275 16.7632Z"
		fill="white"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M28.74 20.9704C28.9076 21.3571 29.3038 21.6066 29.74 21.6C31.6364 21.6158 33.1654 23.0954 33.1667 24.916V25.2C33.1664 25.7672 32.7524 26.2568 32.1742 26.3736C32.1156 26.3851 32.0547 26.3714 32.0075 26.336C31.9581 26.3007 31.9276 26.2462 31.9242 26.1872C31.7873 24.9531 30.7095 24.0129 29.4167 24C28.1143 24.0001 27.0301 24.9601 26.925 26.2064C26.9174 26.3125 26.8274 26.3958 26.7167 26.3992H21.285C21.174 26.3965 21.0836 26.3128 21.0767 26.2064C20.9705 24.9629 19.8894 24.0049 18.5897 24.0026C17.29 24.0003 16.2052 24.9544 16.0942 26.1976C16.0892 26.2547 16.059 26.3071 16.0113 26.3415C15.9635 26.3758 15.9027 26.389 15.8442 26.3776C15.2572 26.2678 14.8335 25.7741 14.8334 25.2V23.6C14.8334 23.0696 15.0529 22.5609 15.4436 22.1858C15.8343 21.8107 16.3642 21.6 16.9167 21.6H18.0517C18.6458 21.6001 19.1579 21.1988 19.2767 20.64L19.5742 19.208C19.7688 18.2732 20.6237 17.6002 21.6167 17.6H25.2242C26.5001 17.6021 27.6467 18.3484 28.1209 19.4856L28.74 20.9704ZM27.4542 21.6C27.5004 21.5998 27.5435 21.5776 27.5692 21.5408V21.5424C27.5958 21.5053 27.6012 21.4577 27.5834 21.416L26.965 19.9312C26.6821 19.2465 25.9915 18.7978 25.2242 18.8H24.4167C24.1866 18.8 24 18.9791 24 19.2V20.4C24 21.0627 24.5597 21.6 25.25 21.6H27.4542Z"
		fill="white"
	/>
	<path
		d="M18.5841 28C19.5046 28 20.2508 27.2836 20.2508 26.4C20.2508 25.5163 19.5046 24.8 18.5841 24.8C17.6637 24.8 16.9175 25.5163 16.9175 26.4C16.9175 27.2836 17.6637 28 18.5841 28Z"
		fill="white"
	/>
	<path
		d="M29.4158 28C30.3363 28 31.0825 27.2836 31.0825 26.4C31.0825 25.5163 30.3363 24.8 29.4158 24.8C28.4953 24.8 27.7491 25.5163 27.7491 26.4C27.7491 27.2836 28.4953 28 29.4158 28Z"
		fill="white"
	/>
</svg>
